html, body {
	padding: 0px;
	margin: 0px;
	background-color: #A1A6B4;
}

button {
	height: 28vmin;
	width: 28vmin;
}

